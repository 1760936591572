import React, { useEffect, useState, useContext } from 'react';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import PaperOnlyPdf from '../OrderFormPDFS/Paper Ordering Only.pdf'
import { NumericTextBox } from "@progress/kendo-react-inputs";

const GridContext = React.createContext({});
const ParentPriceCell = (props) => {
    const { itemChange } = React.useContext(GridContext);
    const ParentPriceChange = (e) => {
        var LatestPrice = 0.00;
        if (e.target.value != null) {
            LatestPrice = e.target.value;
        }
        LatestPrice = LatestPrice.toFixed(2);
        itemChange({ field: "parentPrice", value: LatestPrice, dataItem: props.dataItem })
    };

    return (
        <NumericTextBox
            value={props.dataItem.parentPrice.toFixed(2)}
            onChange={(e) => {
                ParentPriceChange(e)
            }}
        />)
}



const AdditionalItems = (props) => {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        if (!props.formState.additionalItemsOutList)
            props.formState.additionalItemsOutList = [];
        props.formState.additionalItemsOutList = props.formState.additionalItemsOutList.map((item, index) => {

            if (!item.parentPrice) {
                item.parentPrice = 0;
            }

            item.parentPrice = Math.round(item.parentPrice * 100) / 100;

            item.profitAmount = (item.parentPrice - item.discountedItemPrice);
            if (!item.profitAmount)
                item.profitAmount = 0;
            item.profitAmount = Math.round(item.profitAmount * 100) / 100;

            if (!item.discount)
                item.discount = 0;

            if (item.discount > 1)
                item.discount = (item.discount / 100);

            item.discount = Math.round(item.discount * 10000) / 10000;

            item.parentPrice = Math.round(item.parentPrice * 100) / 100;
            return Object.assign(
                {
                    inEdit: true
                },
                item.GradeID = index,
                item
            )
        });
        setData(props.formState.additionalItemsOutList);
    }, []);

    const ProfitAmt = (props) => {
        return <> {props.dataItem.profitAmount <= 0
            ? <td className='text-danger' >${props.dataItem.profitAmount.toFixed(2)}</td>
            : <td>${props.dataItem.profitAmount.toFixed(2) }</td>
        } </>
    }

    const itemChange = (event) => {
        if (event.value < 0)
            return;
        const field = event.field || "";
        if (field == "parentPrice") {
            event.value = Math.round(event.value * 100) / 100;
        }
        const newData = data.map((item) => {
            return item.GradeID === event.dataItem.GradeID
                ? {
                    ...item, [field]: event.value,
                    ["profitAmount"]: (event.value - item.discountedItemPrice.toFixed(2)), changed: "true"
                }
                : item
        }
        );
        props.formState.additionalItemsOutList = newData;
        setData(newData);
    };

    return (<div>
        <label className="Headlabel">
            Please enter the pricing you would like to charge parents for the backpacks and lunch accessories. If you price them at $0, those items will not be available for parents to purchase.
            Parents will only see the price listed under Parent Price. Products being offered can be <a href="//SchoolSpecialty.com/schoolkidz/partners" target="_blank"> viewed here</a>.
        </label>
        <div>
            <GridContext.Provider value={{ itemChange }}>
                <Grid id="AdditionalItemsGridID" data={data} editField="inEdit" resizable={true}
                    dataItemKey={"GradeID"} onItemChange={itemChange}
                    style={{ height: (window.innerHeight - 450) + "px" }}>
                    <Column field="custnmbr" title="Customer No." width="120" editable={false} />
                    <Column field="itemCategory" width={160} title="Item Category" editable={false} />
                    <Column field="itemType" width={170} title="Item Type" editable={false} />
                    <Column field="itemPrice" width={95} title="Item Price (Shipping included)" editable={false} format="{0:c2}" />
                    <Column field="discount" width={85} title="Discount" editable={false} format="{0:p2}" />
                    <Column field="discountAmount" title="Discount Amount" width={90} editable={false} format="{0:c2}" />
                    <Column field="discountedItemPrice" width={100} title="Discounted Item Price" editable={false} format="{0:c2}" />
                    <Column field="parentPrice" title="Parent Price" width={150} editor="numeric" cell={ParentPriceCell} />
                    <Column field="profitAmount" title="Profit Amount" format="{0:c2}" width={130} cell={ProfitAmt} />
                </Grid>
            </GridContext.Provider>
        </div>
    </div>);
};

export default AdditionalItems;