import React, { useEffect, useState } from 'react';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import EditForm from "./editForm";
import APIConnector from "../../../apiconnector"
import CommonVariables from "../../../variables";
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import PrintIcon from '@mui/icons-material/Print';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Notifications from "../../../Notifications/Messages";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router';
import TaskIcon from '@mui/icons-material/Task';
import { event } from 'jquery';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { AddAlertTwoTone } from '@mui/icons-material';
import { WhereAmI } from "../../../UserActivity";
import UserActivity from "../../../UserActivity";


/**
 * This component enables to edit and approve Grade Worksheets
 * 
 */

const Grades = (props) => {

    const navigate = useNavigate();
    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({
        GradeID: 1,
    });
    const [data, setData] = React.useState([]);
    const [AllApprove, setAllApprove] = React.useState(false);
    const initialSort = [
        {
            field: "grade",
            dir: "asc",
        },
    ];
    const [sort] = React.useState(initialSort);
    //#region "     Print Functionality     "
    let gridPDFExport;
    const exportPDF = () => {
        var obj = {
            parameters: `&p_CustomerNumber=${data[0].custnmbr}&p_Approved=1`,
            reportname: CommonVariables.ConfigData.Reports.AllGradeWorksheetsItemsReport
        }
        props.onLoaderRaise(true, "Generating Report ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetReports, obj).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            PdfDownload("GradeWorksheetAll.pdf", resp.reportdata)
        });

    };
    //#endregion
    // let gridPDFExport;
    const exportEachPDF = (item) => {
        var obj = {
            parameters: `&p_CustomerNumber=${item.custnmbr}&p_GradeFrom=${item.grade}&p_GradeTo=${item.grade}&p_Type=${item.type}`,
            reportname: CommonVariables.ConfigData.Reports.IndividualGradeWorksheetItemsReport
        }
        props.onLoaderRaise(true, "Generating Report ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetReports, obj).then(function (resp) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(resp))
                return;
            PdfDownload("GradeWorksheet.pdf", resp.reportdata)
        });
    };
    //#region "     PDF Downlaod Files      "
    const PdfDownload = (name, file) => {
        var fileextension = name.substr(name.lastIndexOf("."));
        if (fileextension === ".jpg" || fileextension === ".png") {
            file = "data:image/png;base64," + file;
        }
        else if (fileextension === ".jpeg") {
            file = "data:image/jpeg;base64," + file;
        }
        else if (fileextension === ".pdf") {
            file = "data:application/pdf;base64, " + file;
        }
        else if (fileextension === ".docx") {
            file = "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " + file;
        }
        else if (fileextension === ".doc") {
            file = "data:application / msword;base64, " + file;
        }
        else {
            file = "data:application/" + fileextension.replace(".", "") + ";base64, " + file;
        }
        triggerBase64Download(file, name)
    };
    //#endregion

    //#region "     Export to Excel functionality    "
    let _export;
    const exportExcel = () => {
        _export.save(data);
    };
    //#endregion



    useEffect(() => {
        props.onLoaderRaise(true);
        APIConnector.Post(CommonVariables.urls.GetUserActivity, {}).then(function (Activityresponse) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(Activityresponse))
                return;
            //As per the Client Request Forcing user to School setup if any of the single user from a school isn't finsihed schoolsetup
            if (Activityresponse.stage == WhereAmI.SchoolSetup) {
                Notifications.ShowWarningMessage("Please complete the School setup first");
                navigate(UserActivity.SwitchUserNavigationBasedOnActivity(Activityresponse));
                return;
            }
            RefreshGradeWSGrid();
        });
    }, []);

   
    
    const RefreshGradeWSGrid = (message) => {
        props.onLoaderRaise(true, "Loading Grade Worksheets ....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.GetAllGradeWorksheet).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setData(response);
            if (message) {
                Notifications.ShowSuccessMessage(message);
            }
            let NotApproved = response.filter(function (each) {
                return each.status != "Approved"
            });
            if (NotApproved && NotApproved.length === 0)
                setAllApprove(true)

        });
    };

    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };

    const enterRowEdit = (rowEvent) => {
        if (rowEvent.dataItem.status === "Approved" || rowEvent.dataItem.status === "Revision Submitted" ||  rowEvent.dataItem.status === "In Progress")
            return;
        enterEdit(rowEvent.dataItem);
    };

    //#region " Submit Functionlaity        "
    const handlesubmit = (event) => {
        let inputJson = {
            CUSTNMBR: editItem.custnmbr,
            Grade: editItem.grade,
            Type: editItem.type,
            Status: "Revision Submitted",
            rowid: editItem.rowid,
            response_note: editItem.response_note
        }
        props.onLoaderRaise(true, "Revision submitting....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGrade, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            RefreshGradeWSGrid("Grade submitted successfully");
        });
    };
    //#endregion

    const handleApprove = (dataitem) => {
        let inputJson = {
            custnmbr: dataitem.custnmbr,
            Grade: dataitem.grade,
            Type: dataitem.type,
            Status: "Approved",
            rowid: dataitem.rowid,
            response_note: dataitem.response_note
        };
        props.onLoaderRaise(true, "Approving....", { marginTop: "145px" });
        APIConnector.Post(CommonVariables.urls.UpdateSchoolGrade, inputJson).then(function (response) {
            props.onLoaderRaise(false);
            if (!CommonVariables.isError(response))
                return;
            setOpenForm(false);
            if (CheckAllAreApprovedOrNot()) {
                Notifications.ShowSuccessMessage("All grade worksheets are approved");
                navigate('/externaldashboard');
            }
            else {
                RefreshGradeWSGrid("Grade approved successfully");
            }
        });
    };

    const CheckAllAreApprovedOrNot = () => {
        let NotApproved = data.filter(function (each) {
            return each.status != "Approved" && each.rowid != editItem.rowid;
        });
        if (NotApproved && NotApproved.length > 0)
            return false;
        else
            return true;
    };

    const handleCancelEdit = (editgriddata) => {

        let temp = editgriddata.filter(element => element.changed)

        if (temp.length === 0) {
            setOpenForm(false);
            RefreshGradeWSGrid("Worksheets refreshed successfully")
        }
        else {
            const AlertNo = () => {
                props.onAlert(false)
            }

            const AlertYes = () => {
                setOpenForm(false);
                RefreshGradeWSGrid("Worksheets refreshed successfully")
                props.onAlert(false)
            }
            var alertDataObj = {
                title: "Please Confirm",
                body: "There are some unsaved changes. Continuing further will lose your changes.  Are you sure you want to exit ?",
                YesTitle: "Yes",
                NoTitle: "No",
                Yes: AlertYes,
                No: AlertNo,
                datatoPassOnYes: "",
            };
            props.onAlert(true, alertDataObj)
        }
    };

    const MyEditCommandCell = (props) => {
        return (
            <td style={{ "textAlign": "center" }} >
                {
                    props.dataItem.status === "Approved" || props.dataItem.status === "Revision Submitted" || props.dataItem.status === "In Progress" ?
                        ""
                        :
                        <RateReviewOutlinedIcon onClick={() => enterEdit(props.dataItem)} title="Review" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer", "textAlign": "center" }} />
                }
            </td>)
    };
    const PrintCommandCell = (props) => {
        return (
            <td style={{ "textAlign": "center" }} >
                <PrintIcon onClick={() => exportEachPDF(props.dataItem)} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
            </td>)
    };

    const ApproveCommandCell = (props) => {
        return (
            <td style={{ "textAlign": "center" }} title="Approve Grade">
                {
                    props.dataItem.status === "Approved" || props.dataItem.status === "Revision Submitted" || props.dataItem.status === "In Progress" || props.dataItem.status === "Revision Not Submitted to SchoolKidz" ?
                        ""
                        :
                        <TaskIcon onClick={() => handleApprove(props.dataItem)} title="Approve" style={{ "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer", "textAlign": "center" }} />
                }
            </td>)
    };

    const rowRender = (trElement, props) => {
        const grey = {
            backgroundColor: "lightgray",
        };
        const white = {
            backgroundColor: "rgb(255,255,255)",
        };
        const trProps = {
            style: props.dataItem.status == "Approved" ? grey : white,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };
    const grid = (
        <Grid className=" rounded-2xl border-b-4" style={{ height: (window.innerHeight - 200) + "px" }}
            data={data}
            pageable={true}
            take={100}
            sortable={true}
            sort={sort}
            total={data.length}
            resizable={true}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single"
            }}
            rowRender={rowRender}
            onRowClick={enterRowEdit}>
            <Column field="grade" title="Grade" width="70" />
            <Column field="custnmbr" title="Acct #" width="80" />
            <Column field="type" title="Type" width="70" />
            <Column field="itemnmbr" title="Item Number" width="140" />
            <Column field="itemdesc" title="Item Description" width="170" />
            <Column field="response_note" title="Response Notes" width="170" />
            <Column field="kitPrice" title="Kit Price" format="{0:n2}" width="110" />
            <Column field="status" title="Status" width="150" />
            <Column cell={MyEditCommandCell} title="Edit" width="60" />
            <Column cell={ApproveCommandCell} title="Approve" width="85" />
            <Column cell={PrintCommandCell} title="Print" width="60" />
        </Grid>
    );

    const TooltipContentTemplate = (props) => {
        return (
            <span>{props.title}</span>
        )
    };
    return <div className="PageStyle" >
        <div>
            {AllApprove && <h2 >All Grade Worksheets have been approved. <br/><span style={{ color: "red" }}>Please move to the Program Schedule by clicking on the dashboard button.</span></h2>}
            <h2 className="inline-block pt-1 primary-heading">Grade Worksheets</h2>
            <div className="row">
                {AllApprove
                    ? <label className="fieldlabel col-sm-10">We recommend printing your grade worksheets and reviewing them for accuracy before proceeding to the Program Schedule.
                        If you need to make changes, please contact your coordinator now. <br></br>To print 
                        worksheets by grade, click the blue printer icon at the end of that grade. To print ALL grade
                        worksheets, click the Print blue icon at the top right of all the grades listed. </label>   
                    : <label className="fieldlabel col-sm-10">If your worksheets are acceptable as shown, click the approve button, it will change status to &apos;approved&apos; and turn gray. Once you approve all lists, <br /> you will gain access to the next step which is our Program Schedule. Please DO NOT approve lists until all revisions have been SUBMITTED and COMPLETED.</label>
                    }
            </div>
            <div className="row" style={{ "marginRight": "auto" }}>
                <div className="col-sm-8">
                </div>
                <div className={AllApprove?"col-sm-3":"col-sm-4"} style={{ marginTop: "auto", marginRight: "auto", textAlign: "end" }}>
                    <span title="Print" style={{ marginRight: "15px", font: "caption" }}>Print
                        <PrintIcon onClick={exportPDF} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                    <span title="Refresh grid" style={{ marginRight: "auto", font: "caption" }}>
                        Refresh
                        <RefreshIcon onClick={RefreshGradeWSGrid} style={{ marginBottom: "7px", marginLeft: "3px", "display": "inline-block", "borderColor": "transparent", "color": "#5454ec", "cursor": "pointer" }} />
                    </span>
                </div>
            </div>
        </div>
        <Tooltip anchorElement={'target'}
            position={'left'}
            content={(props) => <TooltipContentTemplate {...props} />}>
            <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                <Column field="grade" title="Grade" />
                <Column field="custnmbr" title="Customer Number" />
                <Column field="type" title="Type" />
                <Column field="itemnmbr" title="Item Number" />
                <Column field="itemdesc" title="Item Description" />
                <Column field="KitPrice" format="{0:n2}" title="Kit Price" />
                <Column field="status" title="Status" />
                {grid}
            </GridPDFExport>
        </Tooltip>
        {grid}
        {openForm && (
            <EditForm
                cancelEdit={handleCancelEdit}
                onSubmit={handlesubmit}
                item={editItem}
                onLoaderRaise={props.onLoaderRaise}
                onAlert={props.onAlert}
            />
        )}
    </div>;
};

export default Grades;