import React, { useEffect, useState } from 'react';
import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";
import SKpricingHelper from '../OrderFormPDFS/SK pricing Helper.pdf'
import { NumericTextBox } from "@progress/kendo-react-inputs";


const GridContext = React.createContext({});
const ParentPriceCell = (props) => {
    const { itemChange } = React.useContext(GridContext);
    const ParentPriceChange = (e) => {
        var LatestPrice = 0.00;
        if (e.target.value != null) {
            LatestPrice = e.target.value;
        }
        LatestPrice = LatestPrice.toFixed(2);
        itemChange({ field: "parentPrice", value: LatestPrice, dataItem: props.dataItem })
    };

    return (
        <NumericTextBox
            value={props.dataItem.parentPrice.toFixed(2)}
            onChange={(e) => {
                ParentPriceChange(e)
            }}
        />)
}

const Pricing = (props) => {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        if (!props.formState.readKitPricingOutList)
            props.formState.readKitPricingOutList = [];

        props.formState.readKitPricingOutList = props.formState.readKitPricingOutList.map((item, index) => {

            if (!item.parentPrice) {
                item.parentPrice = 0;
            }

            item.parentPrice = Math.round(item.parentPrice * 100) / 100;

            item.profitAmount = (item.parentPrice - item.discountedKitPrice);
            if (!item.profitAmount)
                item.profitAmount = 0;
            item.profitAmount = Math.round(item.profitAmount * 100) / 100;

            if (!item.discount)
                item.discount = 0;

            if (item.discount > 1)
                item.discount = (item.discount / 100);

            item.discount = Math.round(item.discount * 10000) / 10000;


            return Object.assign(
                {
                    inEdit: true
                },
                item.KitID = index,
                item
            )
        });
        setData(props.formState.readKitPricingOutList);
    }, []);

    const ProfitAmt = (props) => {
        return <> {props.dataItem.profitAmount <= 0
            ? <td className='text-danger' >${props.dataItem.profitAmount.toFixed(2)}</td>
            : <td>${props.dataItem.profitAmount.toFixed(2)}</td>
        } </>
    }

    const itemChange = (event) => {
        if (event.value < 0)
            return;
        const field = event.field || "";
        if (field == "parentPrice") {
            event.value = Math.round(event.value * 100) / 100;
        }
        const newData = data.map((item) => {
            return item.KitID === event.dataItem.KitID
                ? { ...item, [field]: event.value, ["profitAmount"]: (event.value - item.discountedKitPrice.toFixed(2)), changed: "true" }
                : item
        });
        setData(newData);
        props.formState.readKitPricingOutList = newData;
    };

    return (<div>
        <label className="Headlabel">
            Please enter the pricing you would like to charge parents for the supply kits.
            Parents will only see the price listed under Parent Price. If you need assistance on how to price your kits,
            please refer to the PDF pricing helper.  <a style={{ textDecoration: "underline" }} href={SKpricingHelper} target="_blank">Pricing PDF</a>. Please pay attention to the <i>Profit Amount</i>  column all the way to the right of the screen.
        </label>
        <div>
            <GridContext.Provider value={{ itemChange }}>
                <Grid data={data} id="PricingKitItemsGridID"
                    editField="inEdit"
                    resizable={true}
                    dataItemKey={"KitID"}
                    style={{ height: (window.innerHeight - 450) + "px" }}
                    onItemChange={itemChange}>
                    <Column field="custnmbr" title="Acct #" width="80" editable={false} />
                    <Column field="kitDescription" title="Kit Description" width="220" editable={false} />
                    <Column field="kitPrice" title="Kit Price (Shipping included)" width="90" format="{0:c2}" editable={false} />
                    <Column field="discount" title="Discount" editable={false} width="100" format="{0:p2}" />
                    <Column field="discountAmount" title="Discount Amount" width="90" editable={false} format="{0:c2}" />
                    <Column field="discountedKitPrice" title="Discounted Kit Price" width="100" editable={false} format="{0:c2}" />
                    <Column field="parentPrice" title="Parent Price" editor="numeric" width="140" cell={ParentPriceCell} />
                    <Column field="profitAmount" title="Profit Amount" format="{0:c2}" width="120" editable={false} cell={ProfitAmt} />
                </Grid>
            </GridContext.Provider>
        </div>
    </div>);
};

export default Pricing;